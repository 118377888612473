import { bindable } from 'aurelia-templating';
import {AlertType, BusinessMap} from 'services/cyber-api';
import $ from 'jquery';
import { DateRangePicker } from 'components/date-range-picker/date-range-picker';
import { Combobox } from 'components/combobox/combobox';
import { autoinject, BindingEngine, computedFrom } from 'aurelia-framework';
import { Disposable } from 'aurelia-binding';
import { IFilter } from './models/filter';
import { FilterNames } from './models/filter-names';
import { defaultFilters } from './models/default-filters';
import { Router } from 'aurelia-router';

@autoinject()
export class FleetHealthFilters {
    // Filtering

    @bindable() private filters: IFilter = defaultFilters;
    @bindable() private filter: ({ filters }) => void;

    // To allow the use of enums and statics in the view, create properties for them
    private FilterNames: typeof FilterNames = FilterNames;

    private defaultFilters: typeof defaultFilters = defaultFilters;
    private fleetHealthFiltersDropdownElement: HTMLDivElement;
    private dropdownElement: HTMLDivElement;
    private dateRangePicker: DateRangePicker;
    private vesselFilter: Combobox;
    private filteredVessels: BusinessMap[];
    private disposables: Disposable[] = [];
    private changeHandlingEnabled: boolean = true;
    private alertTypes: typeof AlertType = AlertType;

    constructor(
        private bindingEngine: BindingEngine,
        private router: Router
    ) {
    }

    private bind(): void {
        // Set default filters
        const initialFilters = Object.assign({}, defaultFilters);

        this.filters = initialFilters;
    }

    // filter by updated
    // upto date
    // out of date

    private attached(): void {
        if (!this.fleetHealthFiltersDropdownElement)
            throw new Error('Filters dropdown element is not defined');

        // Note: it's important to add the observer *after* initial binding to prevent a lot of duplicate filter
        // operations
        this.observeFilterProperties();

        this.addDropdownEventListeners();

        // As the initial binding is ignored because of the presence the bind lifecycle function, manually call filter,
        // only when a filter is active
        if (this.hasActiveFilters)
            this.runFilter();
    }

    private detached(): void {
        this.disposables.forEach(x => x.dispose());
        this.removeDropdownEventListeners();
    }

    private runFilter(): void {
        if (this.filter)
            this.filter({ filters: this.filters });
    }

    private observeFilterProperties(): void {
        // Observe each of the filter properties for changes so filtering can be executed when values change
        const propertyObservers = Object.keys(defaultFilters).map((filterPropertyName) =>
            this.bindingEngine.expressionObserver(this, `filters.${filterPropertyName}`)
        );
        this.disposables.push(
            ...propertyObservers.map((propertyObserver) =>
                propertyObserver.subscribe(this.filterChanged.bind(this))
            ),
        );
    }

    private addDropdownEventListeners(): void {
        // Prevent the filters Bootstrap dropdown from closing when interacting with the filters inside it
        // Note: the dropdown has to be controlled through jQuery
        const $dropdown = $(this.dropdownElement);
        $dropdown.on('click', (event) => {
            if ($(event.target).closest('.fleet-health-filters-dropdown-toggle').length)
                $dropdown.data('closable', true);
            else
                $dropdown.data('closable', false);
        });

        $dropdown.on('hide.bs.dropdown', (event) => {
            // const dateRangePickerIsActive = this.dateRangePicker.isActive();

            const hide = $dropdown.data('closable');

            // Don't hide the filters dropdown when the date range picker is active to prevent clicks within the picker
            // from closing the filters dropdown
                $dropdown.data('closable', true);

            return hide;
        });
    }

    private removeDropdownEventListeners(): void {
        const $dropdown = $(this.dropdownElement);
        $dropdown.off('click');
        $dropdown.off('hide.bs.dropdown');
    }

    private filterChanged(newValue: any, previousValue: any): void {
        if (!this.changeHandlingEnabled)
            return;

        // When navigating, the previousValue is the bind function
        const isNavigating = typeof (previousValue) === 'function';
        // Only update URL filter params when not navigating
        if (!isNavigating) {
            this.setUrlFilterParams();

            this.runFilter();
        }
    }

    private filtersChanged(newValue: any, previousValue: any): void {
        // Ignore the change when the previous value was empty, which should only happen on initialisation
        if (!previousValue)
            return;

        this.runFilter();
    }

    // Observe each of the filter properties for changes to determine the active filters state
    @computedFrom(...Object.keys(defaultFilters).map((x) => `filters.${x}`))
    public get hasActiveFilters(): boolean {
        if (this.filters.updated !== defaultFilters.updated) return true;
        if (this.filters.isContained !== defaultFilters.isContained) return true;
        if (this.filters.isEdrActive !== defaultFilters.isEdrActive) return true;

        return false;
    }

    public async clearFilters(): Promise<void> {
        this.changeHandlingEnabled = false;

        await Promise.all(
            Object.keys(FilterNames)
                .map((x) => this.clearFilter(x as FilterNames))
        );

        this.changeHandlingEnabled = true;
    }

    public async clearFilter(filterName: FilterNames): Promise<void> {
        this.changeHandlingEnabled = false;

        switch (filterName) {
            case FilterNames.Updated:
                this.filters.updated = defaultFilters.updated;
                break;
            case FilterNames.IsEdrActive:
                this.filters.isEdrActive = defaultFilters.isEdrActive;
                break;
            case FilterNames.IsContained:
                this.filters.isContained = defaultFilters.isContained;
                break;
            case FilterNames.Search:
                // These are used outside this component, so don't clear them
                break;
            default:
                console.error('Unable to clear unsupported filter name.');
                break;
        }

        this.changeHandlingEnabled = true;
    }

    private setUrlFilterParams(): void {
        const route = 'fleet-health-monitor';

        // Only update URL when route is within threats
        if (!window.location.pathname.includes(route))
            return;

        const params = new URLSearchParams();

        if (this.filters.updated !== this.defaultFilters.updated)
            params.append('up-to-date', this.filters.updated === 'up-to-date' ? 'true' : 'false');

        if (this.filters.isEdrActive !== this.defaultFilters.isEdrActive)
            params.append('edr-active', this.filters.isEdrActive === 'edr-active' ? 'true' : 'false');

        if (this.filters.isContained !== this.defaultFilters.isContained)
            params.append('is-contained', this.filters.isContained.toString());

        if (this.filters.search !== this.defaultFilters.search)
            params.append('search', this.filters.search);

        // The current URI's search params to compare the new params against
        const existingParams = new URLSearchParams(window.location.search);
        const existingParamsSorted = [...existingParams].sort((a, b) => a[0].localeCompare(b[0]));

        // Sort params for consistency and comparing
        const newParamsSorted = [...params].sort((a, b) => a[0].localeCompare(b[0]));

        // Skip routing when the new params are the same as the current URL params
        if (JSON.stringify(existingParamsSorted) === JSON.stringify(newParamsSorted))
            return;

        // Convert params array to a simple params object the router expects
        const routeParams = {};
        for (const param of newParamsSorted)
            routeParams[param[0]] = param[1];

        // Navigate to the route using the created route params, updating the URL
        this.router.navigateToRoute(route, routeParams);
    }
}
